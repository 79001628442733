<template>
  <div>
    <div id="payslip">
      <div id="scope1">
        <div>
          <div class="title">{{ SocietePrincipale.name }}</div>
          <div>
            Téléphone :
            {{
              SocietePrincipale.telephone ? SocietePrincipale.telephone : "-"
            }}
          </div>
          <div>
            Adresse :
            {{ SocietePrincipale.address ? SocietePrincipale.address : "-" }}
          </div>
          <div>
            IFU : {{ SocietePrincipale.ifu ? SocietePrincipale.ifu : "-" }}
          </div>
          <div>
            RCCM : {{ SocietePrincipale.rccm ? SocietePrincipale.rccm : "-" }}
          </div>
        </div>
        <div>Cotonou, le {{ date }}</div>
      </div>

      <div id="scope">
        <div class="scope-entry">
          <div class="title">INVENTAIRE</div>
        </div>
      </div>

      <div>
        <!-- Block titre start -->
        <div>
          <b-card>
            <b-card-title class="mb-0 row">
              <span class="col1 ml-2 d-flex">
                <!-- <b-badge class="float-right" style="font-size:0.5em;" variant="primary"></b-badge> -->
                <b-icon-check-circle-fill
                  class="mr-1"
                  scale="1.3"
                  variant="primary"
                ></b-icon-check-circle-fill>
                Association :
                {{ toPrint.association ? toPrint.association.name : "" }}
              </span>
              <div class="col border-bottom mx-2 mb-1"></div>
              <span class="col1 mr-2 float-right d-flex">
                <b-badge
                  class="mr-1"
                  style="position: relative; top: -1.5px"
                  variant="primary"
                  >2</b-badge
                >
                PERIODE : Du {{ toPrint.from }} Au
                {{ toPrint.to }}
              </span>
            </b-card-title>
          </b-card>
        </div>
        <!-- Block Titre end -->
        <!-- Block Info Véhicule start -->
        <div class="row pg-childrens">
          <b-card class="col mr-2 px-0 text-center ml-2">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              VÉHICULES
            </h6>
            <div class="row px-1">
              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="success">VENDUS</b-badge>
                <div>{{ toPrint.vehicules_vendus }}</div>
              </div>

              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="dark">STOCK</b-badge>
                <div>{{ toPrint.vehicules_en_stock }}</div>
              </div>

              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="primary">COURS</b-badge>
                <div>{{ toPrint.vehicules_en_cours_de_vente }}</div>
              </div>
            </div>
          </b-card>
          <b-card class="col mr-2 px-0 text-center ml-2">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              DETTES
            </h6>
            <div class="row px-1">
              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="primary">Dédommagements</b-badge>
                <div>{{ $thousandSeparator(toPrint.refunds) }}</div>
              </div>

              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="primary">Ci</b-badge>
                <div>{{ $thousandSeparator(toPrint.total_cartes_imp) }}</div>
              </div>

              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="primary">Chargeurs</b-badge>
                <div>{{ $thousandSeparator(toPrint.total_chargeurs) }}</div>
              </div>
              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="primary">Frais</b-badge>
                <div>{{ $thousandSeparator(toPrint.total_frais) }}</div>
              </div>
              <div class="col p-0" style="font-size: 0.8em">
                <b-badge variant="primary">Factures</b-badge>
                <div>
                  {{ $thousandSeparator(toPrint.total_generation_facture) }}
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="row pg-childrens">
          <b-card class="col mr-2 px-0 text-center">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              montant en caisse
            </h6>
            <span>{{ $thousandSeparator(toPrint.cash) }}</span>
          </b-card>
          <b-card class="col mr-2 px-0 text-center">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              VALEUR DU STOCK
            </h6>
            <span>{{ $thousandSeparator(toPrint.valeur_stock) }}</span>
          </b-card>

          <b-card class="col mr-2 px-0 text-center">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              SOLDE COMPTE
            </h6>
            <span>
              {{ $thousandSeparator(toPrint.montant_comptes) }}
            </span>
          </b-card>

          <b-card class="col mr-2 px-0 text-center">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              MARGE VENTES PÉRIODE
            </h6>
            <span
              >{{ $thousandSeparator(toPrint.benefices_vehicule_en_stock) }}
            </span>
          </b-card>

          <b-card class="col mr-2 px-0 text-center">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              Montant à recouvrer
            </h6>
            <span>{{ $thousandSeparator(toPrint.reste_sur_avance) }} </span>
          </b-card>
          <b-card class="col mr-2 px-0 text-center">
            <h6
              class="border-bottom mb-1 bg-primary rounded text-white"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              DÉPENSES
            </h6>
            <span>{{ $thousandSeparator(toPrint.depenses) }} </span>
          </b-card>
        </div>
        <!-- Block Info Véhicule end -->

        <b-card class="mt-1">
          <h3>Repartition des bénéfices</h3>
          <hr />
          <div>
            <b-table-simple hover medium caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Nom & Prénom(s)</b-th>
                  <b-th>Capital précédent</b-th>
                  <b-th>Retrait</b-th>
                  <b-th>Bénéfice</b-th>
                  <b-th>Nouveau capital</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(item, index) in associes" :key="index">
                  <b-th>{{ item.name }} </b-th>
                  <b-td>{{ $thousandSeparator(item.capital) }}</b-td>
                  <b-td>{{ $thousandSeparator(item.retrait) }}</b-td>
                  <b-td>{{ $thousandSeparator(item.benefice) }}</b-td>
                  <b-td>{{ $thousandSeparator(item.new_capital) }}</b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td variant="secondary"> Total: </b-td>
                  <b-td variant="secondary"></b-td>
                  <b-td variant="secondary">
                    <b>
                      {{
                        $thousandSeparator(
                          associes.reduce(function (sum, current) {
                            return sum + current.retrait * 1;
                          }, 0)
                        )
                      }}</b
                    >
                  </b-td>
                  <b-td variant="secondary"></b-td>
                  <b-td variant="secondary">
                    <b>
                      {{
                        $thousandSeparator(
                          associes.reduce(function (sum, current) {
                            return sum + current.new_capital * 1;
                          }, 0)
                        )
                      }}</b
                    >
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </div>
        </b-card>
      </div>

      <br />
      <br />

      <div id="scope2">
        <div></div>
        <div>
          <div>LE DIRECTEUR GENERAL</div>
          <br /><br />
          <div>{{ SocietePrincipale.responsable }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTable,
  BCardText,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BImg,
  BMedia,
  BMediaAside,
  BButton,
  BMediaBody,
  BIconArrowClockwise,
  BAvatar,
  BBadge,
  BTableSimple,
  BTbody,
  BThead,
  BTh,
  BTd,
  BIconCheckCircleFill,
  BCardTitle,
  BTr,
  BTfoot,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTableSimple,
    BCardTitle,
    BButton,
    BTbody,
    BThead,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BBadge,
    BCard,
    BIconCheckCircleFill,
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BCardText,
    BMediaBody,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BIconArrowClockwise,
    BImg,
  },
  name: "FicheInventaire",

  data: () => ({
    toPrint: {},
    dF: {},
    devises: [],
    dP: {},
    associes: [],
  }),

  computed: {
    SocietePrincipale() {
      return this.societePrincipale
        ? this.societePrincipale
        : {
            name: "STE PAPETERIA SARL",
            address: "Sekandji ",
            telephone: "(+229) 97201630",
            responsable: "NASSIB AZAR",
          };
    },

    montant_synthese() {
      let num = this.toPrint.av_associe_principal;
      let num1 = this.toPrint.av_association;
      let num2 = this.toPrint.reste_avec_association_conv;
      return (
        num +
        num1 +
        num2 +
        this.toPrint.cash +
        this.toPrint.valeur_stock +
        this.toPrint.reste_sur_avance +
        this.toPrint.depenses
      );
    },

    date() {
      var today = new Date();
      var date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      return date;
    },

    totalPaye() {
      return (
        parseInt(this.toPrint.user_salary) +
        parseInt(this.toPrint.current_prime)
      );
    },

    nap() {
      return (
        parseInt(this.totalPaye) -
        parseInt(
          this.toPrint.last_debt_paid ? this.toPrint.last_debt_paid : "0"
        )
      );
    },

    rap() {
      return parseInt(this.toPrint.debt ? this.toPrint.debt : "0");
    },
  },

  mounted() {
    let id = this.$router.currentRoute.params.id;
    this.$http
      .get("/inventaires/" + id)
      .then((response) => {
        if (response.data.success) {
          this.toPrint = response.data.data;
          this.associes = JSON.parse(this.toPrint.repartition);
        }
      })
      .then(() => {
        setTimeout(() => {
          window.print();
        }, 1000);
      });
  },

  methods: {
    telecharger() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.onafterprint = window.close();
    },

    toDate(dateStr) {
      if (dateStr) {
        dateStr = dateStr.replace(/\//g, "-");
        var parts = dateStr.split("-");
        return parts[2] + "-" + parts[1] + "-" + parts[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#payslip {
  background: #fff;
  padding: 0px 40px;
}

#title {
  font-size: 38px;
  font-weight: 600;
}

#title1 {
  font-size: 30px;
  font-weight: 600;
}

#scope {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-around;
}

#scope1 {
  border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-between;
}

#scope2 {
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-between;
}

#scope > .scope-entry {
  text-align: center;
}

#scope > .scope-entry > .value {
  font-size: 14px;
  font-weight: 700;
}

.content {
  display: flex;
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
}

.content .right-panel {
  width: 100%;
}

.theme--light.v-stepper {
  background: transparent !important;
}
.v-stepper {
  border-radius: 0px !important;
  box-shadow: 0px !important;
}

// .title {
//   color: rgba(27, 26, 26, 0.788);
//   font-size: 20px;
//   font-weight: 700;
//   border-bottom: 1px solid #ccc;
//   padding-bottom: 4px;
//   margin-bottom: 6px;
// }

// .content {
//   color: rgba(27, 26, 26, 0.788);
//   font-size: 25px;
//   font-weight: 700;
// }

.content2 {
  color: rgba(27, 26, 26, 0.788);
  font-size: 20px;
  font-weight: 700;
}

// .v-expansion-panel-content__wrap {
//   padding: 0px 5px !important;
// }

.col-2 {
  padding: 6px !important;
}

.v-card__subtitle,
.v-card__title {
  padding: 5px !important;
  margin-bottom: 5px !important ;
}
</style>